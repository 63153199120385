import './AppSidebar.css';

import imgModuleNewTheme from '@elotech/arc/src/assets/img/modules/oxy-logo.svg';
import { Sidebar } from '@elotech/components';
import React from 'react';

type Props = {
  toggledSidebar: boolean;
  showSideBar: boolean;
};

const AppSidebar: React.FC<Props> = props => {
  const { showSideBar, toggledSidebar } = props;
  if (!showSideBar) {
    return null;
  }
  return (
    <Sidebar
      title=""
      imgModuleMain={`${process.env.PUBLIC_URL + '/console-small.png'}`}
      imgModule={`${process.env.PUBLIC_URL + '/console-small.png'}`}
      imgModuleNewTheme={imgModuleNewTheme}
      toggledSidebar={toggledSidebar}
    >
      <Sidebar.Item title="Módulos" to="/" icon="fa fa-tasks" visible />
      <Sidebar.Item
        title="Conta"
        to="/minha-conta"
        icon="fa fa-users"
        visible
      />
    </Sidebar>
  );
};

export default AppSidebar;
