export enum AuthTypes {
  KEYCLOAK_LOADED = 'KEYCLOAK_LOADED',
  USER_EXISTS = 'USER_EXISTS',

  GET_USERDATA = 'GET_USERDATA',
  GET_USERDATA_ERROR = 'GET_USERDATA_ERROR',

  GET_USER_PROFILE = 'GET_USER_PROFILE',
  GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'
}
