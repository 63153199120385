import { Endereco } from '../type';

export const viaCepToEndereco = (value: any): Endereco => {
  return {
    ...value,
    cidade: value.localidade,
    localidade: undefined,
    unidade: undefined,
    gia: undefined
  };
};
