import { BreadCrumbRoute, NotFoundPage } from '@elotech/components';
import { NotificacaoListContainer } from 'common/components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ContaPage from '../pages/conta/ContaPage';
import ModuloPage from '../pages/modulo/ModuloPage';

const routes: BreadCrumbRoute[] = [];

const publicRoutes: BreadCrumbRoute[] = [
  {
    title: 'Notificações',
    path: '/notificacoes',
    component: NotificacaoListContainer,
    icon: 'bell',
    exact: true
  }
];

const renderPublicRoutes = (routes: any) =>
  routes.map((route: any) => <Route {...route} key={route.path || '*'} />);

const Routes = () => (
  <Switch>
    <Route path="/" component={ModuloPage} exact />
    <Route path="/minha-conta" component={ContaPage} exact />
    {renderPublicRoutes(publicRoutes)}
    <Route component={NotFoundPage} />
  </Switch>
);

const allRoutes = [...routes, ...publicRoutes];

export { Routes as default, allRoutes };
