import { AuthTypes } from '../types/auth';
import { ActionType } from '@elotech/components';

const initialState = {
  user: {},
  userExists: {},
  profile: {}
};

const AuthReducer = (
  state = initialState,
  { type, payload }: ActionType<object>
) => {
  switch (type) {
    case AuthTypes.GET_USERDATA: {
      return {
        ...state,
        user: payload
      };
    }

    case AuthTypes.GET_USER_PROFILE: {
      return {
        ...state,
        profile: payload
      };
    }

    default: {
      return state;
    }
  }
};

export default AuthReducer;
