import { Alert, useAuth } from '@elotech/components';
import React, { useEffect } from 'react';

import { PoliticaAcessoService } from '../service';

type Props = { modulo?: string };

const ValidadorPoliticaAcesso: React.FC<Props> = ({ modulo }) => {
  const { logout } = useAuth();
  useEffect(() => {
    PoliticaAcessoService.validaAcesso(modulo).then(response => {
      if (!response.data.grant) {
        Alert.error({
          title: 'Esse acesso viola as políticas de acesso desta entidade',
          text: `Políticas violadas: ${response.data.violations.join(', ')}`,
          footer: 'Entre em contato com os administradores.'
        }).then(() => {
          logout();
        });
      }
    });
  }, [modulo]);
  return <></>;
};

export default ValidadorPoliticaAcesso;
