import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Entidade } from '../type';

const ENTIDADE_API = '/console-api/api/entidades';

export const getEntidadePrincipal = (): AxiosPromise<Entidade> => {
  return axios.get(
    `/unico-api/api/entidades/principal/entidade?fields=nome,brasao,entidadeConsole`
  );
};

export const findAll = (
  search: string,
  pagination?: PageRequest,
  sort?: Sort
): AxiosPromise<PagedResponse<Entidade>> => {
  return axios.get(ENTIDADE_API, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};
