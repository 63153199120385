import './Modulo.css';

import { Alert, Loading } from '@elotech/components';
import { UsuarioPainelService } from 'common/service';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ModuleInfo from '../../components/ModuleInfo';
import ModuleTitle from '../../components/ModuleTitle';

export type ModuleItem = {
  valor: string;
  descricao: string;
  favorito: boolean;
  uri: string;
  area: string;
  idModuloExterno?: string;
  externo: boolean;
};

export type ModuleView = {
  prefix: string;
  suffix: string;
  itens: ModuleItem[];
};

const errorHandling = (error: any, title: string) => {
  Alert.error({ title: title }, error);
};

const ModuloPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modulos, setModulos] = useState([]);
  const user = useSelector((state: any) => state.auth.user);

  const loadModules = () => {
    setIsLoading(true);
    UsuarioPainelService.getModulos()
      .then(response => setModulos(response.data))
      .catch(error => {
        console.log('Erro ao buscar os módulos: ', error);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  const getModulesTitles = (modulos: any[]) => {
    const modulesView: ModuleView[] = [];

    const modulosFavoritos = modulos.filter(m => m.favorito);

    if (modulosFavoritos.length > 0) {
      modulesView.push({
        prefix: 'Meus',
        suffix: 'FAVORITOS',
        itens: modulosFavoritos.map(m => {
          return {
            descricao: m.descricao,
            favorito: true,
            uri: m.uri,
            valor: m.valor,
            area: m.area,
            externo: m.externo,
            idModuloExterno: m.idModuloExterno
          };
        })
      });
    }

    [...new Set(modulos.map(m => m.descricaoArea))].forEach(area => {
      modulesView.push({
        prefix: 'Área',
        suffix: area,
        itens: modulos
          .filter(m => m.descricaoArea === area)
          .map(m => {
            return {
              descricao: m.descricao,
              favorito: m.favorito,
              uri: m.uri,
              valor: m.valor,
              area: m.area,
              externo: m.externo,
              idModuloExterno: m.idModuloExterno
            };
          })
      });
    });

    return modulesView;
  };

  const favoritarModulo = (
    modulo: string,
    favorito: boolean,
    idModuloExterno?: string
  ) => {
    const m = [modulo, idModuloExterno].filter(Boolean).join('-');
    if (favorito) {
      UsuarioPainelService.desfavoritarModulo(m)
        .catch(error => errorHandling(error, 'Erro ao desfavoritar módulo'))
        .finally(() => loadModules());
    } else {
      UsuarioPainelService.favoritarModulo(m)
        .catch(error => errorHandling(error, 'Erro ao favoritar módulo'))
        .finally(() => loadModules());
    }
  };

  useEffect(() => loadModules(), []);

  return (
    <header className="main-content-header">
      <div>
        <h1 id="hello-message" className="hello-message">
          Olá {user.nomeCompleto}, seja bem vindo a plataforma <span>Oxy</span>
        </h1>
        <Loading loading={isLoading} />
        {getModulesTitles(modulos).map(mv => (
          <ModuleTitle key={mv.suffix} prefix={mv.prefix} suffix={mv.suffix}>
            {mv.itens.map(mi => (
              <ModuleInfo
                key={mi.valor}
                valor={mi.valor}
                descricao={mi.descricao}
                favorito={mi.favorito}
                uri={mi.uri}
                clickFavorito={favoritarModulo}
                area={mi.area}
                externo={mi.externo}
                idModuloExterno={mi.idModuloExterno}
              />
            ))}
          </ModuleTitle>
        ))}
      </div>
    </header>
  );
};
export default ModuloPage;
