import React from 'react';
import { connect, FormikContext } from 'formik';
import {
  BasicInput,
  FormikInputInteger,
  Masks,
  Row
} from '@elotech/components';
import MaskedInput from 'react-text-mask';
import { CepService } from '../../service';
import { CepUtils } from '../../utils';
import { FormikUfSelect } from '..';

type Props = {
  fieldName?: string;
};

const FormikEnderecoForm: React.FC<Props & { formik: FormikContext<any> }> = ({
  formik,
  fieldName = 'endereco'
}) => {
  const onChangeCep = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    formik.setFieldValue(`${fieldName}.cep`, value, true);
    formik.setFieldTouched(`${fieldName}.cep`, true, true);

    const onlyNumbers = value.replace(/[^\d]/g, '');
    if (onlyNumbers.length === 8) {
      CepService.findByCep(onlyNumbers).then(response => {
        formik.setFieldValue(
          fieldName,
          CepUtils.viaCepToEndereco(response.data),
          true
        );
      });
    }
  };

  return (
    <>
      <Row>
        <BasicInput
          name={`${fieldName}.cep`}
          label={'CEP'}
          type="text"
          size={2}
          render={({ field }) => (
            <MaskedInput
              {...field}
              mask={Masks.MASK_CEP}
              id={field.name}
              placeholder="Ex. 87047-000"
              onChange={e => onChangeCep(e)}
              className={field.className}
              value={field.value ?? ''}
            />
          )}
        />
        <BasicInput
          name={`${fieldName}.logradouro`}
          label="Logradouro"
          size={6}
        />
        <FormikInputInteger
          name={`${fieldName}.numero`}
          label="Número"
          size={1}
        />
        <BasicInput
          name={`${fieldName}.complemento`}
          label="Complemento"
          size={4}
        />
      </Row>
      <Row>
        <BasicInput name={`${fieldName}.bairro`} label="Bairro" size={4} />
        <BasicInput name={`${fieldName}.cidade`} label="Cidade" size={3} />
        <FormikUfSelect name={`${fieldName}.uf`} />
        <FormikInputInteger name={`${fieldName}.ibge`} label="IBGE" size={2} />
      </Row>
    </>
  );
};

export default connect<Props>(FormikEnderecoForm);
