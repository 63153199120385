import React, { useState } from 'react';

import { ufs } from './ufs';

export type Uf = {
  valor: string;
};

export type UfSelectProps = {
  size?: number;
  name: string;
  value: string;
  onSelect: (name: string, value: string) => void;
};

const UfSelect: React.FC<UfSelectProps> = ({
  name,
  onSelect,
  size = 2,
  value = ''
}) => {
  const [options] = useState<Uf[]>(ufs);

  const onChange = (event: any) => onSelect(name, event.target.value);

  return (
    <div className={`col-md-${size}`}>
      <div className="form-group">
        <label htmlFor="uf-select" className="label">
          UF
        </label>
        <select id="uf-select" onChange={onChange} value={value}>
          <option>Selecione</option>
          {options.map(tipo => (
            <option key={tipo.valor} value={tipo.valor}>
              {tipo.valor}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export { UfSelect };
