export const ufs = [
  { valor: 'AC' },
  { valor: 'AL' },
  { valor: 'AM' },
  { valor: 'AP' },
  { valor: 'BA' },
  { valor: 'CE' },
  { valor: 'DF' },
  { valor: 'ES' },
  { valor: 'GO' },
  { valor: 'MA' },
  { valor: 'MG' },
  { valor: 'MS' },
  { valor: 'MT' },
  { valor: 'PA' },
  { valor: 'PB' },
  { valor: 'PE' },
  { valor: 'PI' },
  { valor: 'PR' },
  { valor: 'RJ' },
  { valor: 'RN' },
  { valor: 'RO' },
  { valor: 'RR' },
  { valor: 'RS' },
  { valor: 'SC' },
  { valor: 'SE' },
  { valor: 'SP' },
  { valor: 'TO' }
];
