import { ActionType } from '@elotech/components';

const initialState = {
  cidade: {
    id: 1
  }
};

const CidadeReducer = (state = initialState, action: ActionType<object>) => {
  return state;
};

export default CidadeReducer;
