import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { KeycloakProvider, KeycloakService } from '@elotech/components';
import { AuthActions } from '../state/actions';

type Props = {
  children: React.ReactNode;
};

const AppKeycloakProvider: React.FC<Props> = ({ children }) => {
  const [isKeycloakLoaded, setKeycloakLoaded] = useState(false);
  const dispatch = useDispatch();

  const setUserProfile = useCallback(
    (payload: object) => dispatch(AuthActions.setUserProfile(payload)),
    [dispatch]
  );

  const getUserData = useCallback(() => dispatch(AuthActions.getUserData()), [
    dispatch
  ]);

  const toggleKeyloakLoaded = () => setKeycloakLoaded(true);

  return (
    <KeycloakProvider
      keycloakService={KeycloakService}
      loadUserProfile={setUserProfile}
      isKeycloakLoaded={isKeycloakLoaded}
      setKeycloakLoaded={toggleKeyloakLoaded}
      checkUserExists={() => Promise.resolve({ payload: { userExists: true } })}
      getUserData={getUserData}
    >
      {children}
    </KeycloakProvider>
  );
};

export default AppKeycloakProvider;
