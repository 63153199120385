import '@elotech/arc/src/assets/less/compiled/tributos.css';
/* Biblioteca de ícones */
import '@fortawesome/fontawesome-free/css/all.css';

import './icons/IconsLibrary';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
