import React from 'react';
import { BasicInput } from '@elotech/components';

type Props = React.ComponentProps<typeof BasicInput> & {};

const FormikInputImageBase64: React.FC<Props> = props => {
  const innerOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (e: React.ChangeEvent<any>) => void,
    name: string
  ) => {
    const { files } = event.target;

    const file = files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const stringResult = reader.result as string;
        const result = stringResult.replace(/.*base64,/, '');
        onChange({ target: { name, value: result } } as React.ChangeEvent<any>);
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <BasicInput
      {...props}
      id={props.id ?? `image-uploader-input-${props.name}`}
      render={({
        field: { value, id, name, className, onChange, ...otherProps },
        form
      }) => (
        <div className="image-uploader">
          <label
            className="image-uploader-label"
            htmlFor={`image-uploader-input-${name}`}
          />
          <input
            type="file"
            id={`image-uploader-input-${name}`}
            className={`image-uploader-input ${className ?? ''}`}
            accept="image/*"
            onChange={event => innerOnChange(event, onChange, name)}
            {...otherProps}
          />
          {value && (
            <>
              <em
                className="image-uploader-remove"
                onClick={() => form.setFieldValue(name, undefined)}
              />
              <div
                className="image-uploader-image"
                style={{ backgroundImage: `url(data:image;base64,${value})` }}
              />
            </>
          )}
        </div>
      )}
    />
  );
};

export default FormikInputImageBase64;
