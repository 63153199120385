import {
  Alert,
  Row,
  SectionTitle,
  useShowNotification
} from '@elotech/components';
import { ProviderService, UserService } from 'common/service';
import { AuthActions } from 'common/state/actions';
import { Provider, UsuarioProvider } from 'common/type';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ProviderCard from './ProviderCard';

type Props = {
  idUsuario: string;
  providersAssociados: UsuarioProvider;
  allowConnect?: boolean;
  afterRemoveProvider?: (provider: Provider) => void;
};

const ContasVinculadasSection: React.FC<Props> = ({
  idUsuario,
  providersAssociados,
  allowConnect,
  afterRemoveProvider
}) => {
  const [loadingProviders, setLoadingProviders] = useState<boolean>(false);
  const [providers, setProviders] = useState<Provider[]>([]);
  const showNotification = useShowNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingProviders(true);
    ProviderService.getAvailableProviders()
      .then(response => setProviders(response.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao buscar os provedores de identidade configurados'
          },
          error
        );
      })
      .finally(() => setLoadingProviders(false));
  }, []);

  const removeProvider = (provider: Provider) => {
    UserService.removeProviderLinkFromUser(idUsuario, provider.provider).then(
      () => {
        showNotification({
          level: 'success',
          title: 'Conta desvinculada com sucesso'
        });
        dispatch(AuthActions.getUserData());
        afterRemoveProvider?.(provider);
      },
      error => {
        Alert.error(
          {
            title: 'Erro ao desvincular a conta'
          },
          error
        );
      }
    );
  };

  const addProvider = (provider: Provider) => {
    ProviderService.getProviderAccountLink(provider.provider)
      .then(response => {
        window.open(response.data, '_self');
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao gerar o link de vinculação'
          },
          error
        );
      });
  };

  const hasProviderVisible =
    providers.length > 0 &&
    providers.some(
      provider =>
        provider.visible || providersAssociados[provider.provider] !== undefined
    );

  if (!loadingProviders && !hasProviderVisible) {
    return null;
  }

  return (
    <>
      <SectionTitle>Contas vinculadas</SectionTitle>
      <Row>
        {providers.map(provider => (
          <ProviderCard
            key={provider.provider}
            provider={provider}
            providerAssociado={providersAssociados[provider.provider]}
            onAddProvider={allowConnect ? addProvider : undefined}
            onRemoveProvider={removeProvider}
          />
        ))}
      </Row>
    </>
  );
};

export default ContasVinculadasSection;
