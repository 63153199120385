import { ReactComponent as OxyAdministrativoIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-administrativo.svg';
import { ReactComponent as OxyGovernoDigitalIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-administrativo.svg';
import { ReactComponent as OxyArrecadacaoIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-arrecadacao.svg';
import { ReactComponent as OxyContabilIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-contabil.svg';
import { ReactComponent as OxyEducacionalIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-educacional.svg';
import { ReactComponent as OxyRecursosHumanosIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-recursos-humanos.svg';
import { ReactComponent as OxySocialIcon } from '@elotech/arc/src/assets/img/modules/logo-oxy-social.svg';
import { Alert } from '@elotech/components';
import { ModuloService } from 'common/service';
import React from 'react';

const LinkOrButton = ({
  uri,
  descricao,
  children,
  externo,
  idModuloExterno
}: {
  uri: string;
  descricao: string;
  externo: boolean;
  idModuloExterno?: string;
  children: React.ReactNode;
}) => {
  if (externo) {
    return (
      <button
        onClick={() =>
          ModuloService.buildModuloExternoUrl(idModuloExterno!)
            .then(response => {
              window.open(response.data.urlGerada, '_blank');
            })
            .catch(error => {
              Alert.error(
                {
                  title: `Erro ao gerar a url para o módulo ${descricao}`
                },
                error
              );
            })
        }
      >
        {children}
      </button>
    );
  }
  return (
    <a
      id={uri.concat('acessar')}
      href={uri}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
};
const ModuleInfo: React.FC<{
  valor: string;
  descricao: string;
  favorito: boolean;
  uri: string;
  clickFavorito: any;
  area: string;
  externo: boolean;
  idModuloExterno?: string;
}> = ({
  valor,
  descricao,
  favorito,
  uri,
  clickFavorito,
  area,
  externo,
  idModuloExterno
}) => {
  return (
    <div className="card">
      <i
        id={uri}
        className={(favorito ? 'fas fa-star active' : 'far fa-star').concat(
          ' pull-right iconFav'
        )}
        onClick={() => clickFavorito(valor, favorito, idModuloExterno)}
        style={{ fontSize: '18px' }}
      ></i>
      <LinkOrButton
        uri={uri}
        descricao={descricao}
        externo={externo}
        idModuloExterno={idModuloExterno}
      >
        {area === 'ADMINISTRATIVO' && <OxyAdministrativoIcon />}
        {area === 'ARRECADACAO' && <OxyArrecadacaoIcon />}
        {area === 'CONTABIL' && <OxyContabilIcon />}
        {area === 'EDUCACIONAL' && <OxyEducacionalIcon />}
        {area === 'RECURSOS_HUMANOS' && <OxyRecursosHumanosIcon />}
        {area === 'SOCIAL' && <OxySocialIcon />}
        {area === 'COMUM' && <OxyAdministrativoIcon />}
        {area === 'GOVERNO_DIGITAL' && <OxyGovernoDigitalIcon />}

        <div className="module-align">
          <span className="module-name">{descricao}</span>
        </div>
      </LinkOrButton>
    </div>
  );
};
export default ModuleInfo;
