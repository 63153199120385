import React from 'react';

const ModuleTitle: React.FC<{
  prefix: string;
  suffix: string;
  children: any;
}> = ({ prefix, suffix, children }) => {
  return (
    <div id={suffix} className="mt-xs ml-xs">
      <div className="title-section">
        <h2 style={{ paddingLeft: '10px' }}>
          {prefix} <span className="module-color">{suffix}</span>
        </h2>
      </div>
      <div className="flex-align mt-xs modulo-items">{children}</div>
    </div>
  );
};
export default ModuleTitle;
