import { Container, NotificacoesFrame } from '@elotech/components';
import React from 'react';

export const NotificacaoListContainer: React.FC = () => {
  return (
    <Container breadcrumb>
      <NotificacoesFrame />
    </Container>
  );
};

export default NotificacaoListContainer;
