import {
  Alert,
  BasicInput,
  Button,
  Col,
  Loading,
  Row,
  Yup,
  useShowNotification
} from '@elotech/components';
import { UserService } from 'common/service';
import { Usuario } from 'common/type';
import { Formik } from 'formik';
import React, { useState } from 'react';

type Props = {
  usuario: Usuario;
};

type ResetPasswordValue = {
  newPassword: string;
  confirmNewPassword: string;
};

const initialValue: ResetPasswordValue = {
  newPassword: '',
  confirmNewPassword: ''
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .label('Nova senha')
    .required(),
  confirmNewPassword: Yup.string()
    .label('Confirmação')
    .required()
    .test('senhasIguais', 'As senhas devem ser iguais.', function(
      value: string
    ) {
      return value === this.parent.newPassword;
    })
});
const ResetPasswordSection: React.FC<Props> = ({ usuario }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showNotification = useShowNotification();

  const onSubmit = (value: ResetPasswordValue) => {
    setLoading(true);
    UserService.resetPassword({
      ...usuario,
      password: value.newPassword,
      temporaryPassword: false
    })
      .then(() => {
        showNotification({
          level: 'success',
          title: 'Senha alterada com sucesso'
        });
        toggleOpen();
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao alterar a senha do usuário'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };
  const toggleOpen = () => setOpen(oldOpen => !oldOpen);
  return open ? (
    <>
      <Loading loading={loading} />
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formProps => (
          <Row>
            <BasicInput
              name="newPassword"
              label="Nova Senha"
              type="password"
              size={3}
            />
            <BasicInput
              name="confirmNewPassword"
              label="Confirme a Senha"
              type="password"
              size={3}
            />
            <Col md={6} className="form-group">
              <label className="label" />
              <Button
                className="inline"
                iconPosition="left"
                onClick={formProps.submitForm}
                type="submit"
              >
                Confirmar
              </Button>
              <Button
                className="inline"
                iconPosition="left"
                onClick={toggleOpen}
                color="neutral"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  ) : (
    <Button onClick={toggleOpen}>Alterar Senha</Button>
  );
};

export default ResetPasswordSection;
