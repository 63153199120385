import { ErrorPage } from '@elotech/components';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    console.error('Erro -> ', error);
    console.error('Error Info -> ', info);

    this.setState({
      hasError: true,
      error,
      info
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage message={this.state.error && this.state.error.toString()} />
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
