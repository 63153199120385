import {
  ApplicationProvider,
  BreadCrumbProvider,
  Footer,
  KeycloakService,
  NotificationContainer,
  SidebarToggle
} from '@elotech/components';
import axios from 'axios';
import classnames from 'classnames';
import { ValidadorPoliticaAcesso } from 'common/components';
import AppKeycloakProvider from 'common/providers/AppKeycloakProvider';
import { EntidadeService } from 'common/service';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ErrorBoundary } from '../src/pages/error/ErrorBoundary';
import keycloakConfig from './keycloak';
import { AppHeader, AppSidebar } from './menus';
import Routes, { allRoutes } from './routes/Routes';
import createStore from './state';

if (!global.Intl) {
  global.Intl = require('intl');
}

const store = createStore({});

KeycloakService.init(keycloakConfig, !!process.env.REACT_APP_LOCAL_KEYCLOAK);

axios.interceptors.request.use(async config => {
  const { ...newConfig } = config;

  const refreshed = await KeycloakService.refreshToken({});
  if (refreshed) {
    newConfig.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
    newConfig.headers['x-tenant-host'] = window.location.host;
  }

  return Promise.resolve(newConfig);
});

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={{}}>
          <IntlProvider locale="pt">
            <NotificationContainer>
              <AppKeycloakProvider>
                <ApplicationProvider
                  onGetEntidadePrincipal={EntidadeService.getEntidadePrincipal}
                >
                  <BreadCrumbProvider routes={allRoutes}>
                    <SidebarToggle>
                      {({ toggledSidebar, userExists, user }) => {
                        return (
                          <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <ValidadorPoliticaAcesso />
                            <div className="wrapper">
                              <AppHeader showMenu={true} currentUser={user} />
                              <AppSidebar
                                showSideBar={true}
                                hasRole={KeycloakService.hasRole}
                              />
                              <section
                                className={classnames({
                                  'main-content': true,
                                  'sidebar-toggle': toggledSidebar,
                                  'no-sidebar': false,
                                  'sidebar-toggle-md': true
                                })}
                              >
                                <ErrorBoundary>
                                  <Routes
                                    hasUsuarioRole={true}
                                    userExists={userExists}
                                    currentUser={user}
                                  />
                                </ErrorBoundary>
                              </section>
                              <Footer />
                            </div>
                          </BrowserRouter>
                        );
                      }}
                    </SidebarToggle>
                  </BreadCrumbProvider>
                </ApplicationProvider>
              </AppKeycloakProvider>
            </NotificationContainer>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
