import { NotificationReducer } from '@elotech/components';
import { AuthReducer } from 'common/state/reducers';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  auth: AuthReducer,
  notification: NotificationReducer
});

export default initialState => {
  // eslint-disable-next-line no-undef, no-underscore-dangle
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk));

  return createStore(reducer, initialState, enhancer);
};
