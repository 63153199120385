import { KeycloakService } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Provider } from '../type';

const realmUrl = () => `/auth/realms/${KeycloakService.getInstance().realm}`;

export const getAvailableProviders = (): AxiosPromise<Provider[]> =>
  axios.get(`${realmUrl()}/providers`);

export const getProviderAccountLink = (
  provider: string
): AxiosPromise<string> =>
  axios.get(`${realmUrl()}/providers/link`, {
    params: {
      provider,
      'redirect-uri': window.location.href
    }
  });
