import { KeycloakService } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { EnumDTO } from '../type';

const resource = () =>
  `/auth/realms/${KeycloakService.getInstance().realm}/modulos`;

export const getModulos = (idEntidade: string): AxiosPromise<EnumDTO[]> =>
  axios.get(`${resource()}/atual/${idEntidade}`);

export const buildModuloExternoUrl = (
  idModuloExterno: string
): AxiosPromise<any> =>
  axios.get(`/painel-api/api/modulos-externos/${idModuloExterno}/url`);
