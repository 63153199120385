import { AuthTypes } from '../types/auth';
import { UserService } from '../../service';
import { Dispatch } from 'react';
import { ActionType } from '@elotech/components';

export const getUserData = () => (dispatch: Dispatch<ActionType<object>>) => {
  return UserService.getUserData().then(response => {
    dispatch({ type: AuthTypes.GET_USERDATA, payload: response.data });
  });
};

export const setUserProfile = (payload: object) => ({
  type: AuthTypes.GET_USER_PROFILE,
  payload
});
