import axios, { AxiosPromise } from 'axios';

import { ResultadoPoliticaAcesso } from '../type';

export const validaAcesso = (
  modulo?: string
): AxiosPromise<ResultadoPoliticaAcesso> =>
  axios.get('/painel-api/api/policies/validate', {
    params: { modulo }
  });
