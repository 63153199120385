import React, { FC } from 'react';
import { FastField, Field, FieldProps } from 'formik';
import { UfSelectProps, UfSelect } from './UfSelect';

type FormikUfSelectProps = Partial<UfSelectProps> & {
  name: string;
  fast?: boolean;
};

const FormikUfSelect: FC<FormikUfSelectProps> = ({
  name,
  fast = true,
  onSelect,
  ...props
}) => {
  const FormikField = fast ? FastField : Field;

  return (
    <FormikField
      name={name}
      render={({ form, field }: FieldProps) => (
        <UfSelect
          {...props}
          {...field}
          onSelect={(name: string, value: string) => {
            if (onSelect) {
              onSelect(name, value);
            } else {
              form.setFieldValue(name, value);
              form.setFieldTouched(name, true);
            }
          }}
        />
      )}
    />
  );
};

export { FormikUfSelect };
