import { Alert, Panel, SectionTitle, Table } from '@elotech/components';
import { UserService } from 'common/service';
import { UsuarioLogin } from 'common/type';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
  idUsuario: string;
};

const UltimosLoginsSection: React.FC<Props> = ({ idUsuario }) => {
  const [logins, setLogins] = useState<UsuarioLogin[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    UserService.getCurrentUserLastLogins()
      .then(response => setLogins(response.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao buscar os últimos logins do usuário'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [idUsuario]);
  return (
    <>
      <SectionTitle>Últimos logins</SectionTitle>
      <Panel isTable>
        <Table
          loading={loading}
          values={logins}
          keyExtractor={(item, index) => `${index}`}
        >
          <Table.Column<UsuarioLogin>
            header="Aplicação"
            value={item => item.modulo}
          />
          <Table.Column<UsuarioLogin>
            header="Data"
            value={item => (
              <>
                <FormattedDate value={item.data} timeZone="UTC" />{' '}
                <FormattedTime value={item.data} />
              </>
            )}
          />

          <Table.Column<UsuarioLogin>
            header="IP Origem"
            value={item => item.ipOrigem}
          />
        </Table>
      </Panel>
    </>
  );
};

export default UltimosLoginsSection;
